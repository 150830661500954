@import './animate.css';
@import './swiper.css';
@import './entry-content.css';
/* Becareful! import only things that can use in all projects and won't have side effects to other ongoing projects! */

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply tw-antialiased tw-overflow-x-hidden;
}

.db {
  border: 1px solid salmon !important;
}

/* Hide recaptcha */
.grecaptcha-badge {
  display: none;
}

i {
  min-width: 0.75rem;
  line-height: 1;
}

.default-chevron-down {
  @apply tw-text-bgh-blue-light  ltr:tw-mr-3 rtl:tw-ml-3;
}

.autocomplete-chevron-down {
  @apply !tw-text-bgh-blue-light tw-text-xs tw-opacity-20 !tw-p-1.5;
}
